import React, { useState, createContext } from 'react';
import { MenuState, MenuContext as Context } from './types';

function getDefaultMenuState():MenuState {
    const DEFAULT_STATE = {
        isActive: false
    };

    return {
        ...DEFAULT_STATE
    };
}


export const MenuContext = createContext<Context>({
    menuState: getDefaultMenuState(),
    toggleMenu: () => {}
});

export const MenuProvider: React.FC = ({ children }) => {
    const [menuState, setMenuState] = useState<MenuState>(getDefaultMenuState());

    const toggleMenu = () => {
        setMenuState({
            ...menuState,
            isActive: menuState.isActive ? false : true
        });
    }

    return (
        <MenuContext.Provider value={{menuState, toggleMenu}}>
            {children}
        </MenuContext.Provider>
    );
}