import React, { useContext } from 'react';
import styled from "styled-components";
import useScrollPosition from "@react-hook/window-scroll";
import useDimensions from 'react-use-dimensions'

import BackgroundImage from 'gatsby-background-image';

import { widths, breakpoints } from "../styles/variables";
import { Hamburger, Modal } from "./Menu";
import CouldBeBlurry from "./helpers/CouldBeBlurry";

import { MenuContext } from '../hooks/Menu/Provider';

import { GatsbySharpFluidImage } from "../types";


const StyledHeader = styled.header``;

const HeaderInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-width: ${widths.lg}px;
`;

const StyledBackgroundHeader = styled.div`
  background-color: #f0f0f0;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  overflow: hidden;
  z-index: 1;

  @media (min-width: ${breakpoints.lg}px) {
    min-height: 380px;
  }
`;
const StyledBackgroundHeaderSpacer = styled.div`
  min-height: 330px;

  @media (min-width: ${breakpoints.lg}px) {
    min-height: 380px;
  }
`;

const StyledBackgroundImageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 330px;

  @media (min-width: ${breakpoints.lg}px) {
    min-height: 380px;
  }

  div:first-child {
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    margin: -5px;
    position: absolute;
    filter: blur(5px);
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.6);
  }
`;

interface HeaderProps {
    backgroundImage?: GatsbySharpFluidImage;
};

const Header: React.FC<HeaderProps> = ( { children, backgroundImage } ) => {
  //global state
  const { menuState } = useContext(MenuContext);
  const scrollY = useScrollPosition(60);
  const [headerRef, headerSize] = useDimensions();
   
  const headerInner = (
    <HeaderInner>
      <CouldBeBlurry isBlurry={menuState.isActive}>
        {children}
      </CouldBeBlurry>
    </HeaderInner>
  )
  let header;
  if(backgroundImage) {
    header = (
      <>
        <StyledBackgroundHeader ref={headerRef}>
          <StyledBackgroundImageWrapper style={{transform: 'scale('+(1.2+(-scrollY*.0001))+')'}}>
            <BackgroundImage fluid={backgroundImage.fluid}/>
          </StyledBackgroundImageWrapper>
          <div style={{transform: 'translateY('+-scrollY/20+'px) scale('+(1+(-scrollY*.0005))+')', opacity: 1+(-scrollY*.003)}}>
          {headerInner}
          </div>
        </StyledBackgroundHeader> 
        <StyledBackgroundHeaderSpacer/>
      </>
    );
  }
  else {
    header = (
      <StyledHeader ref={headerRef}>
        {headerInner}
      </StyledHeader> 
    )
  }

  return (
    <div style={{height: headerSize.height}}>
      <Hamburger needsBackground={scrollY > 0 ? true : false}/>
      {header}
      <Modal/>
    </div>

  )
}

export default Header;
