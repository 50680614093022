import * as React from "react";
import styled from "styled-components";

import { Container } from "./";

import { breakpoints, widths } from "../../styles/variables";

const StyledMiddleContainer = styled(Container)`
    @media (min-width: ${breakpoints.lg}px) {
        margin: 0 auto;
        padding: 0 20px;
        max-width: ${widths.ms}px;
    }
`;

interface ContainerProps {
  className?: string;
  serifFont?: boolean;
  initialLetter?: boolean;
}

const MiddleContainerComponent: React.FC<ContainerProps> = ({ children, className, serifFont, initialLetter }) => (
  <StyledMiddleContainer className={`${className ? className : ''} ${serifFont ? 'has-seriffont' : ''}  ${initialLetter ? 'has-initialLetter' : ''}`}>{children}</StyledMiddleContainer>
);

export default MiddleContainerComponent;
