import * as React from "react";
import styled from "styled-components";

import { colors } from "../styles/variables";

import { Container } from "./content";

const StyledFooter = styled.footer`
    margin: 120px 0 220px 0;
    position: relative;

    &:before {
        left: calc(50% - 30px);
        position: absolute;
        top: -30px;
        width: 60px;
        height: 4px;
        background-color: ${colors.middle};
        border-radius: 2px;
        content: '';
    }
`;

const FooterInner = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Email = styled.div`
    margin-bottom: 9px;
    a {
        text-decoration: none;
        color: ${colors.black}
    }
`;

const Tel = styled.div`
    margin-bottom: 9px;
    a {
        text-decoration: none;
        color: ${colors.black}
    }
`;

const Footer: React.FC = () => (
  <StyledFooter>
    <FooterInner>
        <Email><a href="mailto:adrian@iterations.ch">adrian@iterations.ch</a></Email>
        <Tel><a href="tel:+41 77 480 19 19">+41 77 480 19 19</a></Tel>
    </FooterInner>
  </StyledFooter>
);

export default Footer;
