import * as React from "react";
import styled from "styled-components";

import { Container } from "../content";

const StyledTagContainer = styled(Container)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

interface TagContainerProps {
  className?: string;
}


const TagContainer: React.FC<TagContainerProps> = ({ className, children }) => (
  <StyledTagContainer className={className}>
    { children }
  </StyledTagContainer>
);

export default TagContainer;