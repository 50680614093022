export const cubicBezier = `cubic-bezier(1,.01,.19,.99)`;
export const animationTiming = {
    long: 500,
    short: 250
};


/* Fade Animation */
export const fade = {
    hidden: `
        visibility: hidden;
        opacity: 0;
        transform: translateY(20px) scale(0);
        transition: opacity ${animationTiming.long}ms ${cubicBezier}, visibility ${animationTiming.long}ms ${cubicBezier}, transform ${animationTiming.long}ms ${cubicBezier};
    `,
    visible: `
        visibility: visible;
        opacity: 1;
        transform: translateY(0) scale(1);
    `
}