export const colors = {
  white: "#fff",
  middle: "#D8D8D8",
  black: "#162427",
};

export const fonts = {
  sansSerif:
    '"Lato", sans-serif',
  serif: '"Playfair Display", serif',
  monospace:
    'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, monospace',
};

export const fontTypes = {
  h1: `font-family: ${fonts.sansSerif};
    font-size: 26px;
    color: ${colors.black};
    letter-spacing: 0;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 32px;`,
  h1Desktop: `font-family: ${fonts.sansSerif};
    font-size: 36px;
    color: ${colors.black};
    letter-spacing: 0;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 44px;`,
  h2: `font-family: ${fonts.serif};
    font-style: italic;
    font-size: 16px;
    color: ${colors.black};
    letter-spacing: 0;
    font-weight: 400;
    letter-spacing: .6px;
    line-height: 21px;`,
  h2Desktop: `font-family: ${fonts.serif};
    font-style: italic;
    font-size: 24px;
    color: ${colors.black};
    letter-spacing: 0;
    font-weight: 400;
    letter-spacing: .6px;
    line-height: 32px;`,
  h3: `font-family: ${fonts.sansSerif};
    font-size: 24px;
    color: ${colors.black};
    letter-spacing: 0;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 29px;`,
  h3Desktop: `font-family: ${fonts.sansSerif};
    font-size: 24px;
    color: ${colors.black};
    letter-spacing: 0;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 29px;`,
  h4: `font-family: ${fonts.sansSerif};
    font-size: 18px;
    color: ${colors.black};
    letter-spacing: 0;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 22px;`,
  h4Desktop: `font-family: ${fonts.sansSerif};
    font-size: 24px;
    color: ${colors.black};
    letter-spacing: 0;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 22px;`,
  h5: `font-family: ${fonts.sansSerif};
    font-size: 18px;
    color: ${colors.black};
    letter-spacing: 0;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 22px;`,
  h5Desktop: `font-family: ${fonts.sansSerif};
    font-size: 18px;
    color: ${colors.black};
    letter-spacing: 0;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 29px;`,
  h5Negative: `font-family: ${fonts.sansSerif};
    font-size: 18px;
    color: ${colors.white};
    letter-spacing: 0;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 22px;`,
  h5DesktopNegative: `font-family: ${fonts.sansSerif};
    font-size: 18px;
    color: ${colors.white};
    letter-spacing: 0;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 29px;`,
  cardTitle: `font-family: ${fonts.sansSerif};
    font-size: 16px;
    color: ${colors.black};
    letter-spacing: 0;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 19px;`,
  cardYear: `font-family: ${fonts.sansSerif};
    font-size: 16px;
    color: ${colors.black};
    letter-spacing: 0;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 19px;`,
  small: `font-family: ${fonts.sansSerif};
    font-size: 12px;
    color: ${colors.black};
    letter-spacing: 0;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 15px;`,
  smallNegative: `font-family: ${fonts.sansSerif};
    font-size: 12px;
    color: ${colors.white};
    letter-spacing: 0;
    font-weight: 400;
    letter-spacing: normal
    line-height: 15px;`,
  supersmall: `font-family: ${fonts.sansSerif};
    font-size: 10px;
    color: ${colors.black};
    letter-spacing: 0;
    font-weight: 400;
    letter-spacing: normal`,
  supersmallNegative: `font-family: ${fonts.sansSerif};
    font-size: 10px;
    color: ${colors.white};
    letter-spacing: 0;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 12px;`,
  normal: `font-family: ${fonts.sansSerif};
    font-size: 16px;
    color: ${colors.black};
    letter-spacing: 0;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 24px;`,
  normalNegative: `font-family: ${fonts.sansSerif};
    font-size: 16px;
    color: ${colors.white};
    letter-spacing: 0;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 24px;`,
}

export const breakpoints = {
  xs: 0,
  ms: 550,
  lg: 821,
};

export const widths = {
  xs: 460,
  ms: 642,
  lg: 821,
};
export const dimensions = {
  fontSize: {
    regular: 16,
    large: 18,
    initialLetter: 42
  },
  containerPadding: 40,
};