import * as React from "react";

import styled from "styled-components";

import { fonts, fontTypes, breakpoints } from "../styles/variables";

const StyledLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  ${fontTypes.normal};

  h1 {
    ${fontTypes.h1}

    @media (min-width: ${breakpoints.lg}px) {
        ${fontTypes.h1Desktop}    
    }
  }
  h2 {
    ${fontTypes.h2}

    @media (min-width: ${breakpoints.lg}px) {
        ${fontTypes.h2Desktop}    
    }
  }
  h3 {
    ${fontTypes.h3}

    @media (min-width: ${breakpoints.lg}px) {
        ${fontTypes.h3Desktop}    
    }

  }
  h4 {
    ${fontTypes.h4}

    @media (min-width: ${breakpoints.lg}px) {
        ${fontTypes.h4Desktop}    
    }
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }
`;

const LayoutRoot: React.FC = ({ children }) => (
  <>
    <StyledLayoutRoot>{children}</StyledLayoutRoot>
  </>
);

export default LayoutRoot;
