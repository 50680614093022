import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import "normalize.css";

import { colors, breakpoints } from "../styles/variables";

import Header from '../components/Header';
import LayoutRoot from "../components/LayoutRoot";
import LayoutMain from "../components/LayoutMain";
import Lead from "../components/Lead";
import Footer from "../components/Footer";
import CouldBeBlurry from "../components/helpers/CouldBeBlurry";

import { MenuContext } from '../hooks/Menu/Provider';

import { GatsbySharpFluidImage, TagType } from "../types";

const Content = styled.div`
  z-index: 10;
  background-color: ${colors.white};
  
  &.has-backgroundImage {
    padding-top: 40px;
    box-shadow: 0px -10px 20px -10px rgba(0,0,0,0.2);

    @media (min-width: ${breakpoints.lg}px) {
      padding-top: 100px;
    }
  }
`;

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
    };
  };
}

interface DetailLayoutProps {
  title: string,
  subtitle: string[],
  showLogo?: boolean,
  supLine?: string,
  backgroundImage?: GatsbySharpFluidImage,
  tags?: TagType[],
  moodNegative?: boolean
}

const DetailLayout: React.FC<DetailLayoutProps> = ({ children, title, subtitle, showLogo, supLine, backgroundImage, tags, moodNegative }) => {
  //global state
  const { menuState } = useContext(MenuContext);
  
  return(
    <StaticQuery
      query={graphql`
        query DetailLayoutQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={(data: StaticQueryProps) => (
        <LayoutRoot>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: "description",
                content: data.site.siteMetadata.description,
              },
            ]}
          />
          <Header backgroundImage={backgroundImage}>
            <Lead title={title} supLine={supLine} showLogo={showLogo} moodNegative={moodNegative} tags={tags}>{subtitle}</Lead>
          </Header>
          <Content className={backgroundImage ? 'has-backgroundImage' : ''}>
            <CouldBeBlurry isBlurry={menuState.isActive}>
              <LayoutMain>
                {children}
              </LayoutMain>
              <Footer/>
            </CouldBeBlurry>
          </Content>
        </LayoutRoot>
      )}
    />
  )
};

export default DetailLayout;
