import * as React from "react";
import styled from "styled-components";

import { cubicBezier, animationTiming } from "../../styles/animations";

const CouldBeBlurry = styled.div` 
    transition-duration: ${animationTiming.long}ms;
    transition-property: filter;
    transition-timing-function: ${cubicBezier};

    &.is-blurry {
    }

    width: 100%;
`;

interface LayoutRootProps {
  isBlurry?: boolean;
}

const CouldBeBlurryComponent: React.FC<LayoutRootProps> = ({ children, isBlurry }) => (
  <>
    <CouldBeBlurry className={isBlurry ? 'is-blurry' : ''}>{children}</CouldBeBlurry>
  </>
);

export default CouldBeBlurryComponent;
