import * as React from "react";
import styled from "styled-components";

import { widths, fonts, dimensions } from "../../styles/variables";

const StyledContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-width: ${widths.lg}px;
  box-sizing: border-box;

  &.has-seriffont {
    font-family: ${fonts.serif};
    font-style: italic;
  }

  &.has-initialLetter {
    margin-top: 10px;
    p::first-letter {
      font-size: ${dimensions.fontSize.initialLetter}px;
    }
  }
`;

interface ContainerProps {
  className?: string;
  serifFont?: boolean;
  initialLetter?: boolean;
}

const Container: React.FC<ContainerProps> = ({ children, className, serifFont, initialLetter }) => (
  <StyledContainer className={`${className ? className : ''} ${serifFont ? 'has-seriffont' : ''}  ${initialLetter ? 'has-initialLetter' : ''}`}>{children}</StyledContainer>
);

export default Container;
