import * as React from "react";
import styled from "styled-components";

import { colors, fontTypes } from "../../styles/variables";

const StyledTagContainer = styled.div`
  ${fontTypes.small};
    margin: 0 5px 5px 0;
    padding: 3px 8px;
    border-radius: 14px;
    border: 1px solid ${colors.middle};
    text-decoration: none;

    &.is-negative {
      border-color: ${colors.black}
    }
`;

interface TagProps {
    name: string;
    negative?: boolean;
}

const Tag: React.FC<TagProps> = ({ name, negative }) => (
  <StyledTagContainer className={ negative ? 'is-negative' : '' }>
    { name }
  </StyledTagContainer>
);

export default Tag;