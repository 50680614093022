import * as React from "react";
import styled from "styled-components";

import { fontTypes, breakpoints } from "../styles/variables";
import IterationsIcon from "../../static/icons/Logo.inline.svg";
import IterationsNegativeIcon from "../../static/icons/Logo-Negative.inline.svg";
import { TagType } from "../types";

import TagContainer from "./tags/TagContainer";
import Tag from "./tags/Tag";


const StyledLead = styled.div`
    margin: 0 auto;
`;

const LeadOuter = styled.div`
    margin: 0 auto 44px auto;

    @media (min-width: ${breakpoints.lg}px) {
        margin: 0 auto 74px auto;
    }
`;

const LeadInner = styled.div`
    position: relative;
    margin: 110px 40px 0 40px;
    z-index: 10;

    @media (min-width: ${breakpoints.lg}px) {
        margin: 150px auto 0 auto;
        width: 642px;

        &.has-logo {
            margin: 250px auto 0 auto;
        }
    }
`;

const Logo = styled.div`
    width: 200px;
    height: 193px;
    margin: 25px auto;
    @media (min-width: ${breakpoints.lg}px) {
        position: absolute;
        right: 0px;
        top: -160px;
    }
`;

const LeadText = styled.div`
    @media (min-width: ${breakpoints.lg}px) {
        padding: 0 0 0 20px;
    }
`;

const SupLine = styled.div`
    ${fontTypes.h4};

    @media (min-width: ${breakpoints.lg}px) {
        ${fontTypes.h4Desktop}
    }
`;

const MainLine = styled.div`
    ${fontTypes.h1};

    @media (min-width: ${breakpoints.lg}px) {
        ${fontTypes.h1Desktop}
    }
`;

const AddLine = styled.div`
    ${fontTypes.h2};
    margin-left: 20px;
    margin-top: 10px;

    @media (min-width: ${breakpoints.lg}px) {
        ${fontTypes.h2Desktop}
    }
`;

const LeadTagContainer = styled(TagContainer)`
    margin: 25px 0 0 20px;
    @media (min-width: ${breakpoints.lg}px) {
        margin: 25px 0 0 40px;
    }
`;

interface LeadProps {
    showLogo?: boolean;
    supLine?: string;
    title: string;
    tags: TagType[];
    moodNegative?: boolean;
}

const Lead: React.FC<LeadProps> = ({ children, showLogo, supLine, title, tags, moodNegative }) => (
  <StyledLead>
    <LeadOuter>
        <LeadInner className={showLogo ? 'has-logo' : ''}>
            {showLogo ? (
                <Logo>
                    {moodNegative ? (
                        <IterationsNegativeIcon width={200} height={193}/>
                    ): (
                        <IterationsIcon width={200} height={193}/>
                    )}                    
                </Logo>
            ) : null }
            <LeadText>
                <SupLine>{supLine}</SupLine>
                <MainLine>{title}</MainLine>
                <AddLine>{children}</AddLine>
            </LeadText>
            {tags ? (
                <LeadTagContainer>
                    {tags.map((tag) => (
                        <Tag
                            name={tag.name}
                            key={tag.id}
                            negative={true}/>
                        )
                    )}
                </LeadTagContainer>
            ) : null }        
        </LeadInner>
    </LeadOuter>
  </StyledLead>
);

export default Lead;

