import * as React from "react";
import styled from "styled-components";

import { Container } from "./";
import { GatsbyImageSharpFluid_withWebpType } from "../../types";

import Img from "gatsby-image";

const StyledImageContainer = styled(Container)`
    img {
        max-width: 100%;
        display: block;
    }

    position: relative;
`;

const StyledContent = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    & * {
        color: white !important;
        text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
    }
    padding: 0 20px;
    background: white;
    background: linear-gradient(0deg, rgba(0,0,0,.4) 10%, rgba(0,0,0,0) 100%);
`; 

interface ImageProps {
    imagePath: GatsbyImageSharpFluid_withWebpType;
}

const ImageComponent: React.FC<ImageProps> = ({ children, imagePath}) => (
    <StyledImageContainer>
        <Img fluid={imagePath.childImageSharp.fluid}/>
        {children && (
            <StyledContent>{children}</StyledContent>
        )}
    </StyledImageContainer>
);

export default ImageComponent;
