import React, { useContext } from 'react';
import styled from "styled-components";
import { Link, useStaticQuery, graphql } from "gatsby";

import { fade, cubicBezier, animationTiming } from "../styles/animations";
import { Container } from "./content";

import { breakpoints, colors, fontTypes } from "../styles/variables";

import HamburgerIcon from "../../static/icons/Hamburger.inline.svg";
import CloseIcon from "../../static/icons/Close.inline.svg";

import { MenuContext } from '../hooks/Menu/Provider';
import { PartnerType } from '../types';

const OuterContainer = styled(Container)`
  position: fixed;
  z-index: 100;
  @media (min-width: ${breakpoints.lg}px) {
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

const InnerContainer = styled.div`
  @media (min-width: ${breakpoints.lg}px) {
    width: ${breakpoints.lg}px;
  }
`;

const StyledHamburger = styled.div`
  width: 24px;
  height: 24px;
  margin: 0;
  margin: 20px 0 0 20px;
  position: relative;
  padding: 20px;
  border-radius: 40px;
  transition: box-shadow .8s cubic-bezier(.17,.67,.38,1), transform 0.1s cubic-bezier(0.4, 0.0, 1, 1), background-color .2s cubic-bezier(.17,.67,.38,1);
  cursor: pointer;

  @media (min-width: ${breakpoints.lg}px) {
    margin: 60px 0 0 -20px;
  }

  & svg {
    margin-top: 5px;
    cursor: pointer;
  }

  &:active {  
    transform: scale(.9);
  }

  &.needs-background {
    background-color: ${colors.white};
    box-shadow: 0px 0px 20px -10px rgba(0,0,0,0.5);  
    transform: scale(1.05); 

    &:active {
      box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.3);
      transform: scale(1);
    }
  }
`;

const Close = styled.div`
  margin: 40px 0 0 40px;
  width: 25px;
  height: 25px;
  box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.5);
  transform: scale(0);
  transition-delay: 0s;

  transition-duration: ${animationTiming.short}ms;
  transition-property: transform;
  transition-timing-function: ${cubicBezier};

  & svg {
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.lg}px) {
    margin: 80px 0 0 0;
  }
`;

const StyledBackground = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0,0,0,.95);
  box-shadow: 0px 0px 80px 80px rgba(0,0,0,.95);
  color: #fff;
  z-index: 3000;
  ${fade.hidden};
  transition-delay: ${animationTiming.long}ms;

  &.is-visible {
    ${fade.visible};
    transition-delay: 0s;

    li {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }

    .close-icon {
      transition-delay: ${animationTiming.long}ms;
      transform: scale(1);
    }

    .projektpartner {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Menu = styled.ul`
  margin: 64px 70px;
  padding: 0;

  @media (min-width: ${breakpoints.lg}px) {
    margin: 114px 100px;
  }
`;

const MenuItem = styled.li`
  font-size: 26px;
  color: #FFFFFF;
  letter-spacing: 0;
  margin: 0 0 30px 0;
  list-style-type: none;
  cursor: pointer;

  a {
    text-decoration: none;
    color: #FFFFFF;
  }

  visibility: hidden;
  opacity: 0;
  
  transition-duration: ${animationTiming.long}ms;
  transition-property: transform, visibility, opacity;
  transition-timing-function: ${cubicBezier};
  transition-delay: ${animationTiming.long/3}ms;

  &:first-child {
   transform: translateY(-500%);
  }
  &:nth-child(2) {
    transform: translateY(-1000%);
  }
  &:nth-child(3) {
    transform: translateY(-1500%);
  }
  &:nth-child(4) {
    transform: translateY(-2000%);
  }

  &:hover,
  .is-active {
    font-weight: 700;
  }

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 36px;
    margin: 0 0 40px 0;
  }
`;

const ProjektPartner = styled.div`
  margin-left: 70px;
  position: absolute;
  bottom: 70px;
  width: 100%;

  visibility: hidden;
  opacity: 0;
  transform: translateY(200%);

  transition-duration: ${animationTiming.long}ms;
  transition-property: transform, visibility, opacity;
  transition-timing-function: ${cubicBezier};
  transition-delay: ${animationTiming.long/3}ms;

  a {
    margin: 10px auto;
    display: block; 
    clear: left; 
  }

  @media (min-width: ${breakpoints.lg}px) {
    text-align: center;
    margin-left: 0;
  }
`;

const Title = styled.h5`
  margin-bottom: 25px;
  ${fontTypes.h5Negative};

  @media (min-width: ${breakpoints.lg}px) {
      ${fontTypes.h5DesktopNegative}
  }
`;

export const Modal: React.FC = () => {

  interface partnerData {
    allPartnerJson: {
      nodes: PartnerType[]
    }
  }
  
  //static query
  const partnerData: partnerData = useStaticQuery(graphql`
    query {
      allPartnerJson {
        nodes {
          id
          image {
            publicURL
          }
          image_negative {
            publicURL
          }
          name
          link
          slug
        }
      }
    }
  `)
  //global state
  const { menuState, toggleMenu } = useContext(MenuContext);
  return (
    <StyledBackground className={menuState.isActive ? 'is-visible': ''} onClick={toggleMenu}>
      <Container>
        <Close onClick={toggleMenu} className="close-icon">
          <CloseIcon width={25} height={25}/>
        </Close>
        <nav>
          <Menu>
            <MenuItem><Link to="/" activeClassName="is-active">🏠 Projekte</Link></MenuItem>
            <MenuItem><Link to="/skills" activeClassName="is-active">🤟 Skills</Link></MenuItem>
            <MenuItem><Link to="/about" activeClassName="is-active">🙋‍♂️ About</Link></MenuItem>
            <MenuItem><a href="mailto:adrian@iterations.ch">📧 Kontakt</a></MenuItem>
          </Menu>
        </nav>
      </Container>
      <ProjektPartner className={"projektpartner"}>
        <Title>Projektpartner</Title>
        {partnerData.allPartnerJson.nodes.map((partner) => (
          <a href={partner.link} target="blank" key={partner.id}>
            <img src={partner.image_negative.publicURL}/>
          </a>
        ))}
      </ProjektPartner>
    </StyledBackground>
  )
}

interface HamburgerProps {
  needsBackground?: boolean
}

export const Hamburger: React.FC<HamburgerProps> = ( { needsBackground } ) => {
  //global state
  const { toggleMenu } = useContext(MenuContext);
  return (
    <OuterContainer>
      <InnerContainer>
        <StyledHamburger className={needsBackground ? "needs-background" : ""} onClick={toggleMenu}>
          <HamburgerIcon/>
        </StyledHamburger>
      </InnerContainer>
    </OuterContainer>
  );
};